export default {
  "module-name": "Clients",
  clients: "Clients",
  business: "Business Name",
  logistic_name: "Logistic Name",
  created_at: "Creation date",
  client_info: "Client Info",
  edit_contact: "Edit Contact",
  unspecified: "Unspecified",
  no_articles: "No Articles Added",
  operations: "Operations",
  no_operations: "No Operations Specified",
  view_all: "View all",
  reference: "Reference",
  name: "Name",
  weight: "Weight",
  volume: "Volume",
  dimensions: "Dimensions",
  articles: "Articles",
  article: "Article",
  article_reference: "Article Reference",
  article_reference_lowercase: "Article reference",
  article_measures: "Article Measures",
  article_measures_lowercase: "Article measures",
  client_catalogue: "Client Catalogue",
  edit_article: "Edit Article Info",
  metadata: "Metadata",
  "no-metadata": "There is no Metadata to show",
  measures: "Measures",
  edit_measures: "Edit Measures",
  width: "Width",
  length: "Length",
  height: "Height",
  deleting: "Deleting Article",
  delete_article: "Delete Article",
  delete: "Delete",
  tags: "Tags",
  action_undone:
    "This action can't be undone. Are you sure you want to proceed?",
  "create-article": "New Article",
  location: "Location",
};
