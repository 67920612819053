export default {
  title: "Linehaul Logistics",
  content: "Content",
  "pallets-count": "{count} pallets",
  edit: "Edit",
  creator: "Creator",
  edit_info: "Edit info",
  insert_pallets: "Insert Pallets",
  label_pallets: "Pallets",
  label_origin_platform: "Origin Platform",
  label_destination_platform: "Destination Platform",
  not_especified: "Unspecified",
};
