export default {
  title: "Order Content",
  tab_articles: "Articles",
  tab_packages: "Packages",
  label_ref: "Ref",
  placeholder_ref: "Insert Ref",
  label_name: "Name",
  placeholder_name: "Insert Name",
  label_weight: "Weight",
  placeholder_weight: "Insert Weight",
  label_volume: "Volume",
  placeholder_volume: "Insert Volume",
  label_width: "Width",
  placeholder_width: "Insert Width",
  label_height: "Height",
  placeholder_height: "Insert Height",
  label_length: "Length",
  placeholder_length: "Insert Length",
  label_quantity: "How many packages are there for this article?",
  label_packages_contain: "Packages containing this article",
  placeholder_quantity: "Insert pkgs",
  title_placeholder_empty: "No {content} added",
  subtitle_placeholder_empty: "Get started by adding content",
  deleting: "Deleting in process...",
  label_packages: "Packages",
  label_articles: "Articles",
  label_pallet: "Linehauls.Pallet",
  label_last_scan: "Last scan",
  label_platform: "Platform",
  label_scan_zone: "Scan Zone",
  label_date_time: "Date/Time",
  label_scaned_by: "Scanned by",
  label_client_order: "Client Order ID",
  label_measures: "Measures",
  button_options: "Options",
  button_edit_packages: "Edit packages",
  button_edit_article: "Edit article",
  button_remove_article: "Remove article",
  button_edit: "Edit",
  button_remove_package: "Remove package",
  unspecified: "Unspecified",
};
